import { DropdownFoldMenu } from 'components/Dropdown/menus/Fold/DropdownFoldMenu';
import { postOnIdealistDropdownDataByLocale } from 'mappings/PostOnIdealistDropdown/postOnIdealistDropdownDataByLocale';
import { SIGN_UP_TO_POST_URL_FOR_LOCALE } from 'routing/route-constants';
import { useUser } from 'zustand-stores/userEnvironmentStore';

import {
  postOnIdealistDropdownDataByResourcesItemType,
  postOnIdealistDropdownDataBySearchItemType,
} from './postOnIdealistDropdownConstants';

type Props = {
  locale: UserLocale;
  trackClickedContext: string;
};

export function PostOnIdealistDropdownMenu({
  locale,
  trackClickedContext,
}: Props) {
  const { user } = useUser();
  const { search, resources } = postOnIdealistDropdownDataByLocale[locale];

  return (
    <DropdownFoldMenu
      left={{
        title: search.title,
        items: search.items.map((searchItem) => {
          const { iconName, qaId, trackClickedName } =
            postOnIdealistDropdownDataBySearchItemType[searchItem.type];

          return {
            iconName,
            title: searchItem.title,
            href: user
              ? searchItem.href
              : SIGN_UP_TO_POST_URL_FOR_LOCALE[locale],
            qaId,
            trackClicked: {
              name: trackClickedName,
              context: trackClickedContext,
            },
          };
        }),
      }}
      right={{
        title: resources.title,
        items: resources.items.map((resourceItem) => {
          const { image, imageHover, qaId, trackClickedName } =
            postOnIdealistDropdownDataByResourcesItemType[resourceItem.type];

          return {
            image,
            imageHover,
            title: resourceItem.title,
            description: resourceItem.description,
            href: resourceItem.href,
            qaId,
            trackClicked: {
              name: trackClickedName,
              context: trackClickedContext,
            },
          };
        }),
      }}
    />
  );
}
