import { PostOnIdealistDropdownData } from 'components/PostOnIdealistDropdown/types/PostOnIdealistDropdownData';
import { postAListingRoute } from 'routing/routes';

export const postOnIdealistDropdownDataEn: PostOnIdealistDropdownData = {
  search: {
    title: 'Post',
    items: [
      {
        type: 'post-a-listing',
        title: 'Post a Listing',
        href: postAListingRoute.with({}),
      },
      {
        type: 'create-org',
        title: 'Add your Organization',
        href: '/organizations/create',
      },
      {
        type: 'create-community-group',
        title: 'Add your Community Group',
        href: '/organizations/create',
      },
    ],
  },
  resources: {
    title: 'Resources',
    items: [
      {
        type: 'employer-resources',
        title: 'Employer Resources',
        description:
          'Tools and advice to help you achieve your hiring and retention goals.',
        href: '/en/orgs/resources',
      },
      {
        type: 'help-desk',
        title: 'Help Desk',
        description:
          'We’re here to help you with any and all of your idealist.org questions!',
        href: '/en/help',
      },
    ],
  },
};
