import { PostOnIdealistDropdownData } from 'components/PostOnIdealistDropdown/types/PostOnIdealistDropdownData';
import { postAListingRoute } from 'routing/routes';

export const postOnIdealistDropdownDataEs: PostOnIdealistDropdownData = {
  search: {
    title: 'Publica',
    items: [
      {
        type: 'post-a-listing',
        title: 'Publica una Oportunidad',
        href: postAListingRoute.with({}),
      },
      {
        type: 'create-org',
        title: 'Suma tu Organización',
        href: '/organizations/create',
      },
      {
        type: 'create-community-group',
        title: 'Suma tu Colectivo Ciudadano',
        href: '/organizations/create',
      },
    ],
  },
  resources: {
    title: 'Recursos',
    items: [
      {
        type: 'employer-resources',
        title: 'Herramientas para Reclutadores',
        description:
          'Herramientas y consejos útiles para contratar y retener el mejor talento para tu causa',
        href: '/es/orgs/encuentra-talento',
      },
      {
        type: 'help-desk',
        title: 'Centro de Ayuda',
        description:
          'Espacio para resolver todas y cada una de tus preguntas sobre Idealist.org.',
        href: '/es/ayuda',
      },
    ],
  },
};
