import { IconName } from 'components/Icon/IconName';

import {
  PostOnIdealistDropdownResourcesItemData,
  PostOnIdealistDropdownSearchItemData,
} from './types/PostOnIdealistDropdownData';

export const postOnIdealistDropdownDataBySearchItemType: Record<
  PostOnIdealistDropdownSearchItemData['type'],
  { iconName: IconName; qaId: string; trackClickedName: string }
> = {
  'post-a-listing': {
    iconName: 'pin',
    qaId: 'nav-post-a-listing-link',
    trackClickedName: 'Post a Listing',
  },
  'create-org': {
    iconName: 'plus',
    qaId: 'nav-create-org-link',
    trackClickedName: 'Add your Organization',
  },
  'create-community-group': {
    iconName: 'plus',
    qaId: 'nav-create-community-group-link',
    trackClickedName: 'Add your Community Group',
  },
};

export const postOnIdealistDropdownDataByResourcesItemType: Record<
  PostOnIdealistDropdownResourcesItemData['type'],
  { image: string; imageHover: string; qaId: string; trackClickedName: string }
> = {
  'employer-resources': {
    image: 'illustrations/gear-illustration.svg',
    imageHover: 'illustrations/gear-illustration-hover.svg',
    qaId: 'nav-employer-resources-link',
    trackClickedName: 'Employer Resources',
  },
  'help-desk': {
    image: 'illustrations/talk-illustration.svg',
    imageHover: 'illustrations/talk-illustration-hover.svg',
    qaId: 'nav-help-desk-link',
    trackClickedName: 'Help Desk',
  },
};
